<template>
  <transition name="fade">
    <div id="tooltip" :class="tipObj.type" v-if="tipObj.type">
      <div>{{ tipObj.message }}</div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ToolTip",
  props: ["tipObj"],
  data() {
    return {};
  },
  updated() {
    // console.log(1);
    setTimeout(() => {
      this.tipObj.type = null;
    }, 4000);
  }
};
</script>

<style lang="scss" scoped>
#tooltip {
  width: 300px;
  padding: 10px;
  height: 60px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 120px;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
}
.warning {
  background-color: #f88c10;
}
.error {
  background-color: #f05011;
}
.success {
  background-color: #0ace24;
}

// 动画
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
