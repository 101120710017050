<template>
  <div id="join-us-wrapper">
    <ToolTip :tipObj="tipObj" />
    <div id="title-wrapper">
      <h2>加入我们</h2>
      <div class="underline"></div>
    </div>

    <form id="content-wrapper">
      <div class="row-1 row">
        <div class="fullname">
          <label for="fullname"
            >姓名<span class="required-sign" v-if="!fullname">*</span></label
          >
          <input
            id="fullname"
            type="text"
            placeholder="请输入您的姓名（必填）"
            v-model="fullname"
          />
        </div>
        <div class="email">
          <label for="email">邮箱地址</label>
          <input
            id="email"
            type="text"
            placeholder="请输入您的邮箱地址"
            v-model="email"
          />
        </div>
      </div>
      <div class="row-2 row">
        <div class="phone">
          <label for="phone"
            >手机<span class="required-sign" v-if="!phone">*</span></label
          >
          <input
            id="phone"
            type="text"
            placeholder="请输入您的手机号码（必填）"
            v-model="phone"
          />
        </div>
        <div class="address">
          <label for="address">联络地址</label>
          <input
            id="address"
            type="text"
            placeholder="请输入您的联络地址"
            v-model="address"
          />
        </div>
      </div>
      <div class="message">
        <label for="message">留言</label>
        <textarea
          name=""
          maxlength="300"
          id="message"
          placeholder="请输入留言（300字以内）"
          v-model="message"
        ></textarea>
        <div>{{ charCount }}/300</div>
      </div>
      <div class="btn" @click="handleSubmit">申请</div>
    </form>
  </div>
</template>

<script>
import ToolTip from "@/components/ToolTip";
export default {
  name: "JoinUs",
  components: {
    ToolTip
  },
  data() {
    return {
      fullname: "",
      email: "",
      phone: "",
      address: "",
      message: "",
      charCount: 0,
      tipObj: {
        type: "",
        message: ""
      }
    };
  },
  watch: {
    message(newValue) {
      this.charCount = newValue.length;
    }
  },
  methods: {
    addTip(type = "warning", message = "信息有误") {
      this.tipObj.type = type;
      this.tipObj.message = message;
    },
    checkCommittedInfo() {
      this.tipObj.type = null;
      // 验证必填字段
      if (!this.fullname || !this.phone) {
        this.addTip("warning", "未填必填字段");
      }
      // 验证邮箱
      let emailPattern = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      let emailIsLegal = emailPattern.test(this.email);
      if (!emailIsLegal) {
        this.addTip("warning", "邮箱不合法");
      }
      // 手机
      let phonePattern = /^1[3-9]\d{9}$/;
      let phoneIsLegal = phonePattern.test(this.phone);
      if (!phoneIsLegal) {
        this.addTip("warning", "手机号码不合法");
      }
      // 返回结果
      if (!this.tipObj.type) {
        return true;
      } else {
        return false;
      }
    },
    handleSubmit() {
      let result = this.checkCommittedInfo();
      if (!result) {
        this.addTip("success", "提交成功");
        return;
      }
      let commitedInfo = {
        fullname: this.fullname,
        email: this.email,
        phone: this.phone,
        address: this.address,
        message: this.message
      };
      console.log(commitedInfo);
    }
  }
};
</script>

<style lang="scss" scoped>
#join-us-wrapper {
  color: #000;
  margin-bottom: 100px;
  input:focus {
    outline: none;
  }
}

#title-wrapper {
  margin: 50px 0;
  text-align: center;
  h2 {
    font-size: 36px;
    font-weight: 400;
  }
  .underline {
    width: 50px;
    height: 3px;
    background-color: #004898;
    margin: 20px auto;
  }
}

#content-wrapper {
  margin: 0 auto;
  padding: 20px;
  width: 900px;
  height: 750px;
  box-shadow: 0 0 6px 0 rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;

  label {
    display: block;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  .required-sign {
    color: red;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  > div {
    flex: 1;
    margin: 0 20px;
    input {
      width: 100%;
      height: 50px;
      border: 1px solid #eee;
      border-radius: 10px;
      text-indent: 2em;
      transition: border ease 0.5s;
    }
    input:focus {
      border: 1px solid #004898;
      box-shadow: 2px 2px 2px 0 rgba($color: #626262, $alpha: 0.2);
    }
  }
}
.message {
  margin: 0 20px;
  textarea {
    width: 100%;
    height: 280px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #eee;
    outline: none;
    transition: border ease 0.5s;
    resize: none;
  }
  textarea:focus {
    border: 1px solid #004898;
    box-shadow: 2px 2px 2px 0 rgba($color: #626262, $alpha: 0.2);
  }
}
.btn {
  width: 180px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  margin: 20px auto;
  border-radius: 25px;
  background-color: #004898;
  cursor: pointer;
  transition: all ease 0.3s;
  border: none;
}
.btn:hover {
  background-color: #004898cc;
  box-shadow: 1px 2px 2px 1px rgba($color: #626262, $alpha: 0.2);
}
</style>
